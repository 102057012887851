export const userUrl = "https://api.patelautomotive.in/user/";
export const categoryUrl = "https://api.patelautomotive.in/category/";
export const subCategoryUrl = "https://api.patelautomotive.in/subcategory/";
export const productUrl = "https://api.patelautomotive.in/product/";
export const wishlistUrl = "https://api.patelautomotive.in/wishlist/";



// export const userUrl = "http://localhost:8000/user/";
// export const categoryUrl = "http://localhost:8000/category/";
// export const subCategoryUrl = "http://localhost:8000/subcategory/";
// export const productUrl = "http://localhost:8000/product/";
// export const wishlistUrl = "http://localhost:8000/wishlist/";